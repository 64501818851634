// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  apiUrl: 'https://destekapi.arcayazilim.com/api/',
  // apiUrl: 'https://apidemoevo.arcacloud.com.tr/api/',
  domain: "arcayazilim.com",
  // apiUrl: 'https://apisupport.solutionplus.com.tr/',
  // apiUrl: 'https://destekapi.volltr.com/api/',
  // apiUrl: 'https://localhost:7111/',
  googleClientId: "537800250546-b1eprit06f8cjtfl1buqnpui8tp7rp9l.apps.googleusercontent.com",
  
  
  version:'2024-12-14 2117'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
